import React from "react";
import logoImg from "images/5Ss-large-logo@2x.png";
export default function StrategySection() {
  const data = [
    {
      step: (
        <>
          <span className="fw-bold">S</span>top and Check your Feelings
        </>
      ),
      desc: "This helps prevent a big reaction",
    },
    {
      step: (
        <>
          <span className="fw-bold">S</span>tay Calm
        </>
      ),
      desc: "Use a calm down strategy",
    },
    {
      step: (
        <>
          <span className="fw-bold">S</span>ay It
        </>
      ),
      desc: "Say the problem without blaming anyone",
    },
    {
      step: (
        <>
          <span className="fw-bold">S</span>ize It
        </>
      ),
      desc: "Is it a small, medium, or big problem",
    },
    {
      step: (
        <>
          <span className="fw-bold">S</span>olution Time
        </>
      ),
      desc: "Come up with a solution that will work for everyone",
    },
  ];

  return (
    <section id="strategies" className="pink-section">
      <div className="container">
        <div className="section-column">
          <div className="row gx-5 px-3">
            <div className="col-12 mb-4">
              <p className="h2 display-6 fw-bold">
                Imani the Fixer helps Children Solve Small Problems!!
              </p>
            </div>

            <div className="col-12">
              <p className="fs-5 lh-base mb-4">
              Children have small problems from time to time. Teaching them the tools they need to solve those problems will help them develop skills they will use throughout their lifespan. The Five S's is a great practical strategy that was developed based on what works in research and practice. 
              </p>
            </div>

            <div className="col-12 col-md-3">
              <img className="img-fluid logo-img" src={logoImg} alt="5S logo" />
            </div>
            <div className="col-12 col-md-9">
              <p className="h3 fw-bold">
                The Five S's Problem Solving Strategies
              </p>
              <div className="fs-5 lh-base">
                <div className="accordion accordion-flush" id="allsteps">
                  {data.map((item, idx) => (
                    <div key={idx} className="accordion-item">
                      <h2 className="accordion-header" id={`heading-${idx}`}>
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapse-${idx}`}
                          aria-expanded="false"
                          aria-controls={`collapse-${idx}`}
                        >
                          <div className="fw-normal">{item.step}</div>
                        </button>
                      </h2>
                      <div
                        id={`collapse-${idx}`}
                        className="accordion-collapse collapse"
                        aria-labelledby={`heading-${idx}`}
                        data-bs-parent="#allsteps"
                      >
                        <div className="accordion-body">{item.desc}.</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-12">
              <p className="fs-5 lh-base mt-5">
              <strong>Five S</strong> workshops are available for educators, families, and community partners to support the addition of the <strong>Five S</strong>’s as a component of your social emotional learning program. We provide an interactive experience as we give guidance and answer questions about implementing the <strong>Five S</strong>’s with preschool and elementary aged children. 
              </p>
              <p className="fs-5 lh-base mb-4">
              Please <a href="/?#contact">send an email</a> to schedule your workshop today. 
              </p>
            </div>
          </div>
        </div>
      </div>
      <div id="about-author"></div>
    </section>
  );
}
