import React from "react";
import bookImg from "images/book-cover-image.png";

import { AMAZON_BOOK_LINKS } from "utils/constants";

export default function BookSection() {
  return (
    <section id="buy-book" className="white-section">
      <div className="container">
        <div className="section-column">
          <div className="row">
            <div className="col-12 col-md-4">
              <div className="book-section">
                <img
                  className="img-fluid"
                  src={bookImg}
                  alt="author - clarissa henry"
                />
              </div>
            </div>
            <div className="col-12 col-md-8">
              <div className="col py-5">
                <p className="h2 display-6 fw-bold">
                  Imani the Fixer - Paperback
                </p>
                <p className="h2 display-5 fw-normal">$19.99</p>
                <p className="fs-5 lh-base">
                  Purchase your copy of Imani the Fixer today so you can start to teach the Five S's to the children in your life. Please leave a positive review on Amazon after you receive your book.
                </p>
                <p className="fs-5 lh-base">
                  For orders of 30 or more books, please contact Henry and Jeffers Publishing directly to place your order with discounted pricing. 
                  <br/>
                  <br/>
                  Please <a href="/?#contact">email us</a> to inquire about placing larger orders or to schedule a workshop or an <span className="fw-bold fst-italic">Author Talk**</span>. 
                </p>
                <p className="fs-7 lh-base">
                **The author will read the book with your children and share her story to highlight the importance of growth mindset thinking, perseverance and grit. Sessions can be scheduled with schools, family gorups, community partners and others.
                </p>
              </div>
              <div className="d-grid gap-2 col-6">
                <a
                  href={AMAZON_BOOK_LINKS}
                  className="nav-link"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="btn btn-primary btn-lg">
                    BUY NOW
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
