import React from "react";
// import authorImg from "images/my-queen@2x.png";
// import ImaniImg from "images/imani-large@2x.png";

export default function AuthorSection() {
  return (
    <section className="purple-section">
      <div className="container">
        <div className="section-column">
          <div className="row gx-5">
            <div className="col-12 col-md-12">
              <p className="h2 display-6 fw-bold text-center">
                About the Author
              </p>
              <p className="fs-5 lh-base my-4">
                <span className="fw-bold">Dr. Clarissa Henry</span><br/>
                Dr. Clarissa Henry-Adekanbi is a mother of three children, school psychologist, and educator. For more than two decades, she has worked with children of a variety of ages teaching them the social emotional skills they need to learn and grow. Through her books and daily interactions, Dr. Henry fills her children and students with positive messages to nurture their self-esteem and self-confidence and teaches them the tools they need to fix small problems. Clarissa lives in Connecticut with her family.
              </p>
            </div>
            {/* <div className="col-12 col-md-6 px-5">
              <img
                className="img-fluid author-img"
                src={authorImg}
                alt="author - clarissa henry"
              />
              <img
                className="img-fluid contact-img"
                src={ImaniImg}
                alt="author - clarissa henry"
              />
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
}
