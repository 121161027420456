import React from "react";
import titleImg from "images/itf-title-img@2x.png";

import { AMAZON_BOOK_LINKS } from "utils/constants";

export default function Home() {
  return (
    <section id="home" className="top-section">
      <div className=" top-section-bg">
        <div className="top-section-feature-img">
          <div className="container">
            <div className="section-column">
              <div className="row row-cols-2">
                <div className="col-12 col-md-6">
                  <div className="col">
                    <img
                      className="img-fluid"
                      src={titleImg}
                      alt="imani the fixer text"
                    />
                  </div>
                  <div className="col">
                    <p className="h1 display-3 fw-normal text-center">
                      To the Rescue!
                    </p>
                    <p className="fs-5 lh-base my-4">
                    Imani the Fixer is a social-emotional learning book that teaches children five steps to solve small problems. The main character, Imani, is a superhero who flies all over her hometown, Henryville, teaching children the tools they need to solve small problems by themselves. Using the power of the  Five S's , small problems in Henryville are solved in no time. By the end of the book and with practice, your child should learn how to fix their small problems too. 
                    </p>
                  </div>
                  {/* <div className="d-grid gap-2 col-6 mx-auto text-center">
                    <a
                      href={AMAZON_BOOK_LINKS}
                      className="nav-link"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <button className="btn btn-primary btn-lg">
                        BUY NOW
                      </button>
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
